<script>
import CmpAds from "./cmpAds.vue";

export default {
  components: {CmpAds}

}
</script>

<template>
  <CRow class="body-wrapper overflow-hidden w-100">
    <CCol xs="12" class="w-100 p-0">
      <CCard class="map-wrapper main-card h-100 w-100 p-0">
        <slot name="map"></slot>
        <CCardBody>
          <div class="d-flex position-relative w-100 h-100">
            <cmp-ads class="ads-wrapper animate__animated animate__bounceInDown animate__delay-3s"></cmp-ads>
            <slot></slot>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<style>
.ads-wrapper img{
  box-shadow: 0 0 10px 0 #444;
}
</style>
<style scoped>
.ads-wrapper{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  max-width: 22rem;
}
</style>
